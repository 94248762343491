import ContentlistItem from './Contentlistitem?11'
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from '@material-ui/icons'
import '../css/contentlist.modules.css'
import { useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
// import HorizontalScroll from 'react-scroll-horizontal'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Contentlist = (film, lists) => {
  const movies = film?.film
  // const newlists = film?.lists
  const history = useNavigate()
  const [featured, setFeatured] = useState([])

  return (
    <div className='content-list'>
      <span className='contentlistTitle'>Recommended Films</span>
      <div className='content-wrapper'>
        <div className='content-container'>
          {movies.map((card) => (
            <Link to={`/content/${card.id}`} key={card.id} style={{Display: 'none'}} >
              <ContentlistItem card={card}/> 
            </Link> 
          ))}
        </div>
      </div>
    </div>
  )
}

export default Contentlist
